@import '_fonts';
@import '_swiper';
@import './_reset';
@import './_icons';
@import './_variables';
@import './_mixins';
@import './_reboot';

html {
    scroll-padding-top: 66px;
}

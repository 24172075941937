// .swiper-scrollbar {
//     background: var(--core90);
//     height: 12px !important;
// }
// .swiper-scrollbar-drag {
//     background: var(--core60);
//     height: 12px !important;
// }
// .swiper-scrollbar:hover .swiper-scrollbar-drag {
//     background: var(--core40) !important;
// }
// .swiper-scrollbar .swiper-scrollbar-drag:active {
//     background: var(--core20) !important;
// }
// .isScrollbarMoving .swiper-scrollbar-drag {
//     background: var(--core20) !important;
// }
// .isScrollbarMoving .swiper-scrollbar:hover .swiper-scrollbar-drag {
//     background: var(--core20) !important;
// }

// .isDark .swiper-scrollbar {
//     background: var(--core10) !important;
// }
// .isDark .swiper-scrollbar:hover .swiper-scrollbar-drag {
//     background: var(--core60) !important;
// }
// .isDark .swiper-scrollbar-drag {
//     background: var(--core40) !important;
// }
// .isDark .isScrollbarMoving .swiper-scrollbar-drag {
//     background: var(--core90) !important;
// }
// .isDark .isScrollbarMoving .swiper-scrollbar:hover .swiper-scrollbar-drag {
//     background: var(--core90) !important;
// }

.swiper-scrollbar {
    background: var(--core10) !important;
    height: 12px !important;
}
.swiper-scrollbar:hover .swiper-scrollbar-drag {
    background: var(--core60) !important;
}
.swiper-scrollbar-drag {
    background: var(--core40) !important;
    height: 12px !important;
}
.isScrollbarMoving .swiper-scrollbar-drag {
    background: var(--core90) !important;
}
.isScrollbarMoving .swiper-scrollbar:hover .swiper-scrollbar-drag {
    background: var(--core90) !important;
}

.swiper-custom-pagination.swiper-pagination-progressbar.swiper-pagination-horizontal {
    background: var(--core80);
    margin-top: 12px;
    margin-bottom: 12px;
    height: 1px;

    span {
        background: var(--core5);
    }
}
.swiper-button-next,
.swiper-button-prev {
    opacity: 0;
}
.swiper {
    &:hover {
        .swiper-button-next,
        .swiper-button-prev {
            opacity: 1;
        }
    }
}

.isDark {
    .swiper-custom-pagination.swiper-pagination-progressbar.swiper-pagination-horizontal {
        background: var(--core5);
        height: 1px;

        span {
            background: var(--core95);
        }
    }
}

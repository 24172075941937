$icons-font: "icons";

@font-face {
    font-family: $icons-font;
    src: url("../fonts/icon/icons.eot?35f1f3f5886a5336b8855fae12170cc3#iefix") format("embedded-opentype"),
url("../fonts/icon/icons.woff2?35f1f3f5886a5336b8855fae12170cc3") format("woff2"),
url("../fonts/icon/icons.woff?35f1f3f5886a5336b8855fae12170cc3") format("woff");
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$icons-map: (
    "arrow_forward": "\f101",
    "arrow-up": "\f102",
    "burger": "\f103",
    "chapters": "\f104",
    "chevron_left": "\f105",
    "close": "\f106",
    "club": "\f107",
    "diamond": "\f108",
    "heart": "\f109",
    "less": "\f10a",
    "pause": "\f10b",
    "play": "\f10c",
    "plus": "\f10d",
    "small-arrow": "\f10e",
    "small-circle": "\f10f",
    "spade": "\f110",
);

.icon-arrow_forward:before {
    content: map-get($icons-map, "arrow_forward");
}
.icon-arrow-up:before {
    content: map-get($icons-map, "arrow-up");
}
.icon-burger:before {
    content: map-get($icons-map, "burger");
}
.icon-chapters:before {
    content: map-get($icons-map, "chapters");
}
.icon-chevron_left:before {
    content: map-get($icons-map, "chevron_left");
}
.icon-close:before {
    content: map-get($icons-map, "close");
}
.icon-club:before {
    content: map-get($icons-map, "club");
}
.icon-diamond:before {
    content: map-get($icons-map, "diamond");
}
.icon-heart:before {
    content: map-get($icons-map, "heart");
}
.icon-less:before {
    content: map-get($icons-map, "less");
}
.icon-pause:before {
    content: map-get($icons-map, "pause");
}
.icon-play:before {
    content: map-get($icons-map, "play");
}
.icon-plus:before {
    content: map-get($icons-map, "plus");
}
.icon-small-arrow:before {
    content: map-get($icons-map, "small-arrow");
}
.icon-small-circle:before {
    content: map-get($icons-map, "small-circle");
}
.icon-spade:before {
    content: map-get($icons-map, "spade");
}

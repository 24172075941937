@font-face {
    font-family: 'Cadiz';
    font-style: normal;
    font-weight: 1000;
    src: url('../fonts/CadizWeb-Black.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Cadiz';
    font-style: italic;
    font-weight: 1000;
    src: url('../fonts/CadizWeb-BlackItalic.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Cadiz';
    font-style: normal;
    font-weight: 220;
    src: url('../fonts/CadizWeb-Book.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Cadiz';
    font-style: italic;
    font-weight: 220;
    src: url('../fonts/CadizWeb-BookItalic.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Cadiz';
    font-style: normal;
    font-weight: 560;
    src: url('../fonts/CadizWeb-SemiBold.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Cadiz';
    font-style: italic;
    font-weight: 560;
    src: url('../fonts/CadizWeb-SemiBoldItalic.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Cadiz';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/CadizWeb-Bold.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Cadiz';
    font-style: italic;
    font-weight: 700;
    src: url('../fonts/CadizWeb-BoldItalic.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Cadiz';
    font-style: normal;
    font-weight: 1;
    src: url('../fonts/CadizWeb-Light.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Cadiz';
    font-style: italic;
    font-weight: 1;
    src: url('../fonts/CadizWeb-LightItalic.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Cadiz';
    font-style: normal;
    font-weight: 340;
    src: url('../fonts/CadizWeb-Regular.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Cadiz';
    font-style: italic;
    font-weight: 340;
    src: url('../fonts/CadizWeb-RegularItalic.woff') format('woff');
    font-display: swap;
}

@use './mixins';
@use './variables';
*,
*::before,
*::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
html {
    overflow-x: hidden;
}

.no-scroll {
    overflow: hidden !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 1000000%;
    height: 100000%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    overflow-x: hidden;
    @include screen('medium') {
        width: 100%;
        height: 100%;
    }
}
body {
    font-family: $font-stack;
    font-weight: $font-weight;
    font-size: 20px;
    background-color: var(--primaryTextColor);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(#000, 0);
    -webkit-font-smoothing: initial;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

strong {
    font-weight: bold;
}

img,
svg {
    vertical-align: middle;
}

img {
    max-width: 100%;
}

a {
    color: inherit;
    @include style-font(20px, 24px, -0.4px);
    text-decoration-line: none;
}
